import React from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight} from "../../assets/icons/icons"

export default function PagePrivacy() {
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">Privacy Policy</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/index">Go Green IT Solutions</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link >Privacy Policy</Link></li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h5 className="text-xl font-semibold mb-4">Overview :</h5>
                                <p className="text-slate-400">

                                Go Green IT Solutions, LLC Privacy Policy

<p className="text-green-600">Introduction</p>

Welcome to Go Green IT Solutions ("we," "our," or "us"). This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website and services.

<p className="text-green-600">Information We Collect</p>

Personal Information: We may collect personal information, including but not limited to names, contact information, and billing details when you register for our services or make a purchase.

Usage Information: We may collect information about how you use our website and services, including IP addresses, device information, browser type, and pages visited.

<p className="text-green-600">How We Use Your Information</p>

We use the collected information for the following purposes:

To provide and maintain our services
To process transactions and send invoices
To improve our website and services
To communicate with you, including responding to inquiries and providing customer support
To comply with legal obligations

<p className="text-green-600">Cookies and Tracking Technologies</p>

We use cookies and similar tracking technologies to enhance your experience on our website. You can control the use of cookies through your browser settings.

<p className="text-green-600">Third-Party Disclosure</p>

We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy.

<p className="text-green-600">Data Security</p>

We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.

<p className="text-green-600">Your Choices</p>

You can choose not to provide certain information, but it may affect your ability to use some features of our website or services.

<p className="text-green-600">Children's Privacy</p>

Our website and services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children.

<p className="text-green-600">Changes to this Privacy Policy</p>

We reserve the right to update our Privacy Policy at any time. Changes will be effective immediately upon posting on our website.

<p className="text-green-600">Contact Information</p>

If you have any questions or concerns about our Privacy Policy, please contact us at contact@gogreenits.com
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
