import React, {useState} from 'react'
import { Link } from 'react-router-dom';

import amazon from '../../assets/images/client/amazon.svg';
import google from '../../assets/images/client/google.svg';
import lenovo from '../../assets/images/client/lenovo.svg';
import paypal from '../../assets/images/client/paypal.svg';
import shopify from '../../assets/images/client/shopify.svg';
import spotify from '../../assets/images/client/spotify.svg';
import cta_bg from '../../assets/images/cta-bg.jpg';
import image from '../../assets/images/client/01.jpg';
import image1 from '../../assets/images/client/02.jpg';
import image2 from '../../assets/images/client/03.jpg';
import image3 from '../../assets/images/client/04.jpg';
import hero2 from '../../assets/images/hero2.png';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import TinySlider from 'tiny-slider-react';
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";
import * as Icon from 'react-feather';
import {MdKeyboardArrowRight} from '../../assets/icons/icons'
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';
import WhoWeAre from '../../component/whoWeAre';

const settings = {
    container: '.tiny-single-item',
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
}
export default function PageAboutus() {
    const [isOpen, setOpen] = useState(false)

    const services = [
        {
            description: `" If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "`,
            name: 'Thomas Israel',
            image: amazon
        },
        {
            description: `" If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "`,
            name: 'Carl Oliver',
            image: google

        },
        {
            description: `" If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "`,
            name: 'Barbara McIntosh',
            image: lenovo
        },
        {
            description: `" If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "`,
            name: 'Jill Webb',
            image: paypal
        },
        {
            description: `" If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "`,
            name: 'Dean Tolle',
            image: shopify
        },
        {
            description: `" If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page consists of a more or less random series of words or syllables. "`,
            name: 'Christa Smith',
            image: spotify
        }
    ]
    const teamData = [
        {
            image:image,
            name:"Ronny Jofra",
            title:"C.E.O.",
            desc:"If the distribution of letters and 'words' is random"
        },
        {
            image:image1,
            name:"Aliana Rosy",
            title:"HR",
            desc:"If the distribution of letters and 'words' is random"
        },
        {
            image:image2,
            name:"Sofia Razaq",
            title:"C.O.O.",
            desc:"If the distribution of letters and 'words' is random"
        },
        {
            image:image3,
            name:"Micheal Carlo",
            title:"Director",
            desc:"If the distribution of letters and 'words' is random"
        },
    ]
    return (
        <>
            <Navbar navClass="nav-light" />
            <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/company/aboutus.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-75"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">About</h3>

                        <p className="text-slate-300 text-lg max-w-xl mx-auto"></p>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className=" tracking-[0.5px]  mb-0 inline-flex items-center space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Go Green IT Solutions</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link >About</Link></li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <WhoWeAre/>
                <div className="container relative mt-8">
                </div>
            </section>
            <Footer />
        </>
    )
}
