import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight} from "../../assets/icons/icons"
import { accordionData } from '../../data/dataTwo';

export default function PageTerms() {
    const [activeIndex, setActiveIndex] = useState(0);
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    return (
        <>
            <Navbar />

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">Terms of Services</h3>
                    </div>
                </div>
                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/index">Go Green IT Solutions</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link >Terms of Service</Link></li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"> 
                                <h5 className="text-xl font-semibold mb-4 mt-8">User Agreements :</h5>
                                <p className="text-slate-400">Go Green IT Solutions, LLC Terms of Service</p>

<p className="text-green-600">Acceptance of Terms</p>
By accessing or using Go Green IT ("we," "our," or "us") services, you agree to comply with and be bound by these Terms of Service.

<p className="text-green-600">Description of Services</p>
Go Green IT Solutions provides [brief description of your IT services]. These services are subject to the terms and conditions outlined in this agreement.

<p className="text-green-600">User Registration</p>
To access certain features of our services, you may be required to register for an account. You agree to provide accurate and current information during the registration process and to update such information to keep it accurate and current.

<p className="text-green-600">User Obligations</p>
You agree not to engage in any activity that may disrupt or interfere with our services, including but not limited to hacking, data mining, or transmitting malicious software.

<p className="text-green-600">Privacy</p>
Our Privacy Policy, available on our website, explains how we collect, use, and disclose information about our users. By using our services, you agree to the terms of our Privacy Policy.

<p className="text-green-600">Intellectual Property</p>
All content and materials available on our website, including but not limited to text, graphics, logos, and software, are the property of Go Green IT Solutions and are protected by intellectual property laws.

<p className="text-green-600">Limitation of Liability</p>
Go Green IT Solutions shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use our services.

<p className="text-green-600">Termination</p>
We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without notice, for any reason, including if you violate these Terms of Service.

<p className="text-green-600">Governing Law</p>
These Terms of Service shall be governed by and construed in accordance with the laws of Georgia. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Georgia.
 
<p className="text-green-600">Changes to Terms</p>
We reserve the right to modify or replace these Terms of Service at any time. Your continued use of our services after any such changes constitutes your acceptance of the new terms.

<p className="text-green-600">Contact Information: Address</p>
Go Green IT Solutions, 1100 Peachtree Street, Suite 250, Atlanta, GA 30309

<p className="text-green-600">Contact Information:Email</p>
contact@gogreenits.com

<p className="text-green-600">Contact Information:Phone</p>
855-564-2066

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
