import logo1 from '../assets/images/client/9.png';
import logo2 from '../assets/images/client/10.png';
import logo3 from '../assets/images/client/11.png';
import logo4 from '../assets/images/client/12.png';
import logo5 from '../assets/images/client/13.png';
import logo6 from '../assets/images/client/14.png';

import consulting1 from '../assets/images/consulting/1.jpg';
import consulting2 from '../assets/images/consulting/2.jpg';
import consulting3 from '../assets/images/consulting/3.jpg';

export const accordionData = [
    {
        id: 1,
        title: 'How does it work ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 2,
        title: 'Do I need a designer to use Techwind ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 3,
        title: 'What do I need to do to start selling ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 4,
        title: 'What happens when I receive an order ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 5,
        title: 'How does it work ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 6,
        title: 'Do I need a designer to use Techwind ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 7,
        title: 'What do I need to do to start selling ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id: 8,
        title: 'What happens when I receive an order ?',
        content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    }
]
export const partnerImage = [logo1, logo2, logo3,logo4,logo5,logo6]

export const consultingServices = [
    {
        image:consulting1,
        title:"Digital Transformation",
        desc:"",
    },
    {
        image:consulting2,
        title:"Enterprise Application Delivery",
        desc:"",
    },
    {
        image:consulting3,
        title:"Infrastructure Modernization",
        desc:"",
    },
]
