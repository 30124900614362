import React from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';

import {FaArrowRight} from '../../assets/icons/icons'

import { consultingServices } from '../../data/dataTwo';

const settings = {
    container: '.tiny-single-item',
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
}
export default function IndexConsulting() {
    return (
        <>
            <Navbar navClass="nav-light" />
            <section className="relative md:py-60 py-36 bg-[url('../../assets/images/consulting/bg.jpg')] bg-right bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-slate-900/70"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 items-center mt-10">
                        <span className="text-white/80 font-semibold mb-4 text-lg">Go Green IT Solutions</span>
                        <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">Tranform your business <br /> with Digital Transformation</h4>
                        <p className="text-white/60 text-lg max-w-xl">Technology Subject Matter Experts with wide range of skills to help deliver innovation solutions.</p>

                        <div className="mt-8">
                            <Link to="#" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Learn More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-6 border-t border-b border-gray-100 dark:border-gray-700">
                <div className="container relative">
                    <CompanyLogo/>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Technology Consulting Services</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">We partner with our customers to tranform your digital landscape.</p>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        {consultingServices.map((item,index)=>{
                            return(
                                <div className="group relative" key={index}>
                                    <div className="relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                        <img src={item.image} className="" alt="" />
                                        <div className="absolute inset-0 bg-slate-900/30 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                                    </div>

                                    <div className="mt-6">
                                        <Link to="#" className="text-xl font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out">{item.title}</Link>

                                        <p className="text-slate-400 mt-4">{item.desc}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <Footer />
            <CookieModal />
        </>
    )
}
