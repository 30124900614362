import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/index';
import Navbar from './component/Navbar/navbar';
import Partners from './pages/partners/partners';
import Terms from './pages/utility/terms';
import Services from './pages/company/services';
import Privacy from './pages/utility/privacy';
import About from './pages/company/about';
import Documentation from './pages/documentation';
import Contact from './pages/contact/contact';
import Changelog from './pages/changelog';
import Switch from './component/Switch';
import UiComponents from './pages/uiComponents';

export default function App() {
    return (
        <BrowserRouter>
        <Switch/>
            <Routes>
                <Route path="/" element={<Navigate to="/index" />} />
                <Route exact path="/index" element={<Index />} /> 
                <Route exact path="/navbar" element={<Navbar />} />
                <Route exact path="/partners" element={<Partners />} />
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/privacy" element={<Privacy />} />
                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/documentation" element={<Documentation />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/changelog" element={<Changelog />} />
                <Route exact path="/ui-components" element={<UiComponents/>} />
            </Routes>
        
        </BrowserRouter>
    )
}
