import facebook_logo_2019 from '../assets/images/client/facebook-logo-2019.png';
import google_logo from '../assets/images/client/google-logo.png';
import linkedin from '../assets/images/client/linkedin.png';
import skype from '../assets/images/client/skype.png';
import spotify from '../assets/images/client/spotify.png';
import telegram from '../assets/images/client/telegram.png';
import circle_logo from '../assets/images/client/circle-logo.png';
import lenovo_logo from '../assets/images/client/lenovo-logo.png';
import shree_logo from '../assets/images/client/shree-logo.png';
import snapchat from '../assets/images/client/snapchat.png';
import android from '../assets/images/client/android.png';


import { FiPhone, FaRegEnvelope, RiMapPinLine} from "../assets/icons/icons"
export   const jobGrid = [
        {
            id:1,
            title: 'Software Engineering',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$950 - $1100/mo',
            image: facebook_logo_2019,
            company: 'Facebook Ltd.',
            name:'Facebook',
            place: 'Australia',
            city:"Commonwealth"
        },
        {
            id:2,
            title: 'Web Developer',
            date: 'Posted 3 Days ago',
            duration: 'Remote',
            money: '$2500 - $2600/mo',
            image: google_logo,
            company: 'Google Ltd.',
            name:'Google',
            place: 'America',
            city:"Yerevan"
        },
        {
            id:3,
            title: 'UX/UI Designer',
            date: 'Posted 3 Days ago',
            duration: 'Freelance',
            money: '$3500 - $3600/mo',
            image: linkedin,
            company: 'Linkedin Ltd.',
            name:'Linkedin',
            place: 'Canada',
            city:"Ottawa"
        },
        {
            id:4,
            title: 'Human Resource(HR)',
            date: 'Posted 3 Days ago',
            duration: 'Part Time',
            money: '$2000 - $2500/mo',
            image: skype,
            company: 'Skype Ltd.',
            name:'Skype',
            place: 'Indonesia',
            city:"Jakarta"
        },
        {
            id:5,
            title: 'Web Designer',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$1500 - $1600/mo',
            image: spotify,
            company: 'Spotify Ltd.',
            name:'Spotify',
            place: 'Greece',
            city:"	Athens"
        },
        {
            id:6,
            title: 'Graphic Designer',
            date: 'Posted 3 Days ago',
            duration: 'Part time',
            money: '$500 - $600/mo',
            image: telegram,
            company: 'Telegram Ltd.',
            name:'Telegram',
            place: 'Germany',
            city:"	Berlin"
        },
        {
            id:7,
            title: 'Senior Web Developer',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$950 - $1100/mo',
            image: circle_logo,
            company: 'Circle CI Ltd.',
            name:'Circle',
            place: 'Australia',
            city:"	Canberra"
        },
        {
            id:8,
            title: 'Front-End Developer',
            date: 'Posted 3 Days ago',
            duration: 'Remote',
            money: '$2500 - $2600/mo',
            image: lenovo_logo,
            company: 'Lenovo Ltd.',
            name:'Lenovo',
            place: 'Austria',
            city:"Vienna"
        },
        {
            id:9,
            title: 'Back-End Developer',
            date: 'Posted 3 Days ago',
            duration: 'Freelance',
            money: '$3500 - $3600/mo',
            image: shree_logo,
            company: 'Shreethemes Ltd.',
            name:'Shreethemes',
            place: 'Canada',
            city:"	Ottawa"
        },
        {
            id:10,
            title: 'Data Entry',
            date: 'Posted 3 Days ago',
            duration: 'Part Time',
            money: '$2000 - $2500/mo',
            image: snapchat,
            company: 'Snapchat Ltd.',
            name:'Snapchat',
            place: 'France',
            city:"Paris"
        },
        {
            id:11,
            title: 'Android Developer',
            date: 'Posted 3 Days ago',
            duration: 'Full Time',
            money: '$1500 - $1600/mo',
            image: android,
            company: 'Android Ltd.',
            name:'Android',
            place: 'China',
            city:"Beijing"
        },
        {
            id:12,
            title: 'Sketch Designer',
            date: 'Posted 3 Days ago',
            duration: 'Part time',
            money: '$500 - $600/mo',
            image: shree_logo,
            company: 'Shreethemes Ltd.',
            name:'Shreethemes',
            place: 'India',
            city:"New Delhi"
        },
    ]
export const footerLinks = [
    {
        liClass: '',
        route: '/terms',
        title: 'Terms of Services',

    },
    {
        liClass: 'mt-[10px]',
        route: '/privacy',
        title: 'Privacy Policy',

    }
];
export const footerCompany = [
    {
        liClass: 'mt-[10px]',
        route: '/services',
        title: 'Services',

    },
    {
        liClass: 'mt-[10px]',
        route: '/partners',
        title: 'Partners',

    },
    {
        liClass: 'mt-[10px]',
        route: '/about',
        title: 'About',
    },

    {
        liClass: 'mt-[10px]',
        route: '/contact',
        title: 'Contact',

    }
]
export const contactData = [
    {
     icon:FiPhone,
     title:"Phone",
     desc:"",
     contact:"888-564-2066"
    },
    {
     icon:FaRegEnvelope,
     title:"Email",
     desc:"",
     contact:"contact@gogreenits.com"
    },
    {
     icon:RiMapPinLine,
     title:"Location",
     desc:"",
     contact:"View on Google map"
    }
 ]

